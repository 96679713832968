/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { CacheProvider } from '@emotion/react'
import { RemixBrowser } from '@remix-run/react'
import { startTransition, StrictMode } from 'react'
import * as React from 'react'
import { hydrateRoot } from 'react-dom/client'

import ClientStyleContext from '~/styles/client.context.tsx'
import createEmotionCache from '~/styles/createEmotionCache.ts'

interface ClientCacheProviderProps {
	children: React.ReactNode
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
	const [cache, setCache] = React.useState(createEmotionCache())

	const reset = React.useCallback(() => {
		setCache(createEmotionCache())
	}, [])

	return (
		<ClientStyleContext.Provider value={{ reset }}>
			<CacheProvider value={cache}>{children}</CacheProvider>
		</ClientStyleContext.Provider>
	)
}

if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
	// if (ENV.SENTRY_DSN) {
	import('./utils/monitoring.client.tsx').then(({ init }) => init())
}

// startTransition(() => {
// 	hydrateRoot(
// 		document,
// 		<StrictMode>
// 			<RemixBrowser />
// 		</StrictMode>,
// 	)
// })
const callback = () =>
	startTransition(() => {
		hydrateRoot(
			document,
			<StrictMode>
				<ClientCacheProvider>
					<RemixBrowser />
				</ClientCacheProvider>
			</StrictMode>,
		)
	})

if (process.env.NODE_ENV === 'development') {
	import('remix-development-tools').then(({ initClient }) => {
		// Add all the dev tools props here into the client
		initClient()
		callback()
	})
} else {
	callback()
}
